<script setup>
const { t } = useI18n();

// ### METHODS
function getSunsetFeatureCard(width, height) {
  return {
    'img': `https://a.hwstatic.com/image/upload/f_auto,q_auto,w_${width},h_${height}/v1676366319/pwa/ups1`,
    'subtitle': t('t_SEEWHOISGOING'),
    'title': t('t_VIEWTRAVELLERPROFILES'),
    'bg-color': 'sunset',
    'class': 'card-item',
    'width': width,
    'height': height,
  };
}
function getTealFeatureCard(width, height) {
  return {
    'img': `https://a.hwstatic.com/image/upload/f_auto,q_auto,w_${width},h_${height}/v1676366319/pwa/usp2`,
    'subtitle': t('t_CHATWITHTRAVELLERS'),
    'title': t('t_JOINYOURHOSTELSCHAT'),
    'bg-color': 'teal',
    'class': 'card-item',
    'width': width,
    'height': height,
  };
}
function getBlueFeatureCard(width, height) {
  return {
    'img': `https://a.hwstatic.com/image/upload/f_auto,q_auto,w_${width},h_${height}/v1676366319/pwa/usp3`,
    'subtitle': t('t_EXPLOREANDHANGOUT'),
    'title': t('t_RSVPTOLINKUPS'),
    'bg-color': 'blue',
    'class': 'card-item',
    'inverted': true,
    'width': width,
    'height': height,
  };
}
</script>

<template>
  <section class="features-carousel-section">
    <HomepageHwFeaturesCarouselCard
      class="card-small"
      v-bind="getSunsetFeatureCard('312', '501')"
    />
    <HomepageHwFeaturesCarouselCard
      class="card-large"
      v-bind="getSunsetFeatureCard('241', '382')"
    />

    <HomepageHwFeaturesCarouselCard
      class="card-small"
      v-bind="getTealFeatureCard('312', '501')"
    />
    <HomepageHwFeaturesCarouselCard
      class="card-large"
      v-bind="getTealFeatureCard('241', '382')"
    />

    <HomepageHwFeaturesCarouselCard
      class="card-small"
      v-bind="getBlueFeatureCard('143', '230')"
    />
    <HomepageHwFeaturesCarouselCard
      class="card-large"
      v-bind="getBlueFeatureCard('241', '382')"
    />
  </section>
</template>

<style scoped lang="scss">
.features-carousel-section {
  display: flex;
  gap: $wds-spacing-m;
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .card-item {
    width: 100%;
    min-width: 13rem;
    scroll-snap-align: center;
    overflow: hidden;

    &.card-small {
      display: flex;
    }

    &.card-large {
      display: none;
    }
  }

  @include tablet-large {
    .card-item.card-small {
      display: none;
    }

    .card-item.card-large {
      display: flex;
    }
  }
}
</style>
