<script setup>
import { WdsButtonLink, WdsCampaignBanner } from '@wds/components';

// ### COMPOSABLES
const { t } = useI18n();
const { $tracking } = useNuxtApp();

// ### STORES
const testingStore = useTestingStore();

const isCampaignEnabled = testingStore.hasEnabledFeature('web_europeTravelDeals');
const campaignVars = testingStore.getFeatureVariables('web_europeTravelDeals');

// ### VARIABLES
const campaignProperties = campaignVars?.featured_props?.properties || [];
const showCampaignCarousel = isCampaignEnabled && campaignVars?.homepage?.showCarousel && campaignProperties.length;

const maxDiscount = campaignProperties.reduce((acc, property) => {
  if (!property?.tag?.match(/^\d+%$/)) {
    return acc;
  }

  const discount = Number.parseInt(property?.tag?.replace('%', ''));
  acc = discount > acc ? discount : acc;
  return acc;
}, 0);

// ### METHODS
function getUrlToEuropeDeals(tcs) {
  return `https://global.hostelworld.com/europe-travel-deals${tcs ? '#tcs' : ''}`;
}
function handleBannerClick() {
  $tracking.triggerCampaignBannerEventAction({ action: 'Banner Clicked' });
}
function handleTCClick() {
  $tracking.triggerCampaignBannerEventAction({ action: 'Terms and Conditions Clicked' });
}
function handleAllDealsClick() {
  $tracking.triggerCampaignBannerEventAction({ action: 'All Deals Clicked' });
}
function handlePropertyCardClick(property, index) {
  $tracking.triggerCampaignBannerEventAction({
    action: 'Carousel Clicked',
    carousel_property_clicked: property?.propertyId,
    carousel_offer_displayed: property?.tag,
    carousel_position_clicked: index + 1,
  });
}
</script>

<template>
  <div
    v-if="isCampaignEnabled"
    class="campaign-container"
  >
    <template v-if="showCampaignCarousel">
      <div class="campaign-carousel">
        <h2>{{ t('t_EUROPEDEALSTITLE') }}</h2>
        <div class="inner-wrapper">
          <p class="text">
            <span>{{ t('t_EUROPEDEALSSUBTITLE', { maxdiscount: maxDiscount }) }}&nbsp;</span>
            <a
              :href="getUrlToEuropeDeals(true)"
              class="tclink"
              rel="noopener"
              @click="handleTCClick"
            >{{ $t('t_TCAPPLY') }}</a>
          </p>

          <PromotionsHwPopularDealsCards
            :properties="campaignProperties"
            @property-click="handlePropertyCardClick"
          />

          <div class="campaign-view-all">
            <WdsButtonLink
              :href="getUrlToEuropeDeals(false)"
              :text="t('t_DISCOVERALLDEALS')"
              :title="t('t_DISCOVERALLDEALS')"
              :arial-label="t('t_DISCOVERALLDEALS')"
              rel="noopener"
              icon-end="chevron-right"
              class="campaign-view-all-btn"
              @click="handleAllDealsClick"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <WdsCampaignBanner
        bg-image="https://a.hwstatic.com/image/upload/v1731408608/campaign/backgrounds/small/europe-deals-bg.png"
        :link="getUrlToEuropeDeals(false)"
        :title="$t('t_EUROPEDEALSTITLE')"
        title-tag-color="pink"
        :subtitle="$t('t_EUROPEDEALSSUBTITLE')"
        subtitle-color="white"
        :tc-link="getUrlToEuropeDeals(true)"
        :tc-text="$t('t_TCAPPLY')"
        :button-text="$t('t_DISCOVERALLDEALS')"
        class="campaign-banner"
        @tc-click="handleTCClick"
        @banner-click="handleBannerClick"
        @button-click="handleAllDealsClick"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
$campaign-banner-large: url('https://a.hwstatic.com/image/upload/v1731408485/campaign/backgrounds/large/europe-deals-bg.png');

.campaign-banner {
  margin-bottom: $wds-spacing-m;
}

.campaign-carousel {
  margin: 0 auto $wds-spacing-m;
  background-image: $campaign-banner-large;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  min-height: wds-rem(510px);
  border-radius: $wds-spacing-m;
  padding-top: $wds-spacing-l;
  line-height: $wds-line-height-body-2;

  .inner-wrapper {
    padding-block: $wds-spacing-m;
    display: flex;
    flex-direction: column;
  }

  h2 {
    @include title-2-bld;

    background-color: $wds-color-pink;
    color: $wds-color-white;
    word-wrap: break-word;
    padding: $wds-spacing-xs $wds-spacing-s;
    clip-path: polygon(0 0, 100% 0, calc(100% - 4px) 100%, 0 100%);
    text-align: left;
    margin-right: $wds-spacing-m;

    @include tablet {
      @include title-1-bld;

      width: fit-content;
      padding: $wds-spacing-xs $wds-spacing-xl;
    }
  }

  .text {
    @include body-2-reg;

    color: $wds-color-white;
    z-index: 1;
    display: inline;
    width: fit-content;
    font-weight: $wds-font-weight-bold;
    padding-inline: $wds-spacing-m;
  }

  .tclink {
    color: $wds-color-white;
    text-decoration: underline;
    cursor: pointer;
    padding: $wds-spacing-s 0;

    &:focus-within,
    &:focus,
    &:focus-visible,
    &:active {
      border-radius: $wds-spacing-s;
      box-shadow:
        wds-rem(1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(-1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(1px) 0 0 wds-rem(2px) $wds-color-ink-darker,
        wds-rem(-1px) 0 0 wds-rem(2px) $wds-color-ink-darker;
    }
  }

  .campaign-view-all {
    padding-inline: $wds-spacing-m;

    .campaign-view-all-btn {
      background: $wds-color-white;
      color: $wds-color-ink-darker;
      fill: $wds-color-ink-darker;
      text-decoration: none;
      width: 100%;
    }

    &:focus-within {
      box-shadow:
        wds-rem(1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(-1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(1px) 0 0 wds-rem(2px) $wds-color-ink-darker,
        wds-rem(-1px) 0 0 wds-rem(2px) $wds-color-ink-darker;
    }

    @include tablet {
      width: fit-content;
      align-self: flex-end;
    }
  }
}
</style>
