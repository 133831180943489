<script setup>
import { WdsButtonLink } from '@wds/components';

const { t } = useI18n();

const { useUrl } = useUrls();

const testingStore = useTestingStore();
const { $tracking } = useNuxtApp();

const bfData = testingStore.getFeatureVariables('web_blackFriday');

const bfProperties = computed(() => {
  return bfData?.featured_props?.properties || {};
});

// METHODS
function getUrlToBlackFriday(termsAndConditionsKey) {
  const termsAndConditionsUrl = termsAndConditionsKey === 1 ? '#terms-and-conditions' : '';
  return `${useUrl.getStaticBaseURL()}deals/black-friday/${termsAndConditionsUrl}`;
}
function handleTCClick() {
  $tracking.onBFTermsConditionsClick();
}
function handleAllDealsClick() {
  $tracking.onBFAllDealsClick();
}
function handlePropertyCardClick(property, index) {
  $tracking.onBFLPDealClick(property, index, 'homepage');
}
</script>

<template>
  <template v-if="bfProperties?.length">
    <div class="bf-banner">
      <h2>Black Friday</h2>
      <div class="inner-wrapper">
        <p class="text">
          {{ $t('t_BFLPHERO1') }}
          <a
            :href="getUrlToBlackFriday(1)"
            class="tclink"
            rel="noopener"
            @click="handleTCClick"
          >{{ $t('t_TCAPPLY') }}</a>
        </p>

        <PromotionsHwPopularDealsCards
          :properties="bfProperties"
          @property-click="handlePropertyCardClick"
        />

        <WdsButtonLink
          :href="getUrlToBlackFriday(0)"
          :text="t('t_DISCOVERALLDEALS')"
          :title="t('t_DISCOVERALLDEALS')"
          :arial-label="t('t_DISCOVERALLDEALS')"
          rel="noopener"
          icon-end="chevron-right"
          class="bf-view-all"
          @click="handleAllDealsClick"
        />
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.bf-banner {
  margin: 0 auto $wds-spacing-m;
  background-image: url('~/assets/images/black-friday/bg-hp-small.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  min-height: wds-rem(510px);
  border-radius: $wds-spacing-m;
  padding: $wds-spacing-l 0;
  line-height: $wds-line-height-body-2;

  @include tablet {
    background-image: url('~/assets/images/black-friday/bg-hp-large.svg');
  }

  .inner-wrapper {
    padding: $wds-spacing-m;
    display: flex;
    flex-direction: column;
  }

  h2 {
    @include title-1-bld;

    background-color: $wds-color-pink-dark;
    color: $wds-color-white;
    word-wrap: break-word;
    padding: $wds-spacing-xs 0;
    text-align: center;

    @include tablet {
      width: fit-content;
      padding: $wds-spacing-xs $wds-spacing-xl;
      clip-path: polygon(0 0, 100% 0, calc(100% - 4px) 100%, 0 100%);
    }
  }

  .text {
    @include body-2-reg;

    color: $wds-color-white;
    z-index: 1;
    display: inline;
    width: fit-content;
  }

  .tclink {
    color: $wds-color-white;
    text-decoration: underline;
    cursor: pointer;
    padding: $wds-spacing-s;

    &:focus-within,
    &:focus,
    &:focus-visible,
    &:active {
      border-radius: $wds-spacing-s;
      box-shadow:
        wds-rem(1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(-1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(1px) 0 0 wds-rem(2px) $wds-color-ink-darker,
        wds-rem(-1px) 0 0 wds-rem(2px) $wds-color-ink-darker;
    }
  }

  .bf-view-all {
    background: $wds-color-white;
    color: $wds-color-ink-darker;
    width: 100%;
    padding: 0 $wds-spacing-m;
    cursor: pointer;
    align-self: flex-end;
    text-decoration: none;

    &:focus-within {
      box-shadow:
        wds-rem(1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(-1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(1px) 0 0 wds-rem(2px) $wds-color-ink-darker,
        wds-rem(-1px) 0 0 wds-rem(2px) $wds-color-ink-darker;
    }

    @include tablet {
      width: fit-content;
    }
  }
}
</style>
